import { defaults } from 'lib/layouts/DefaultLayout'
import { createStyles } from 'utils/css'
import { ColorProps } from 'utils/types'

export const containerStyle = (brandColor: ColorProps) =>
  createStyles(({ radii, mediaQuery }) => ({
    borderRadius: `${radii.lg} 0 0 ${radii.lg}`,
    [mediaQuery(defaults.breakpoint)]: {
      borderLeft: `8px solid ${brandColor.hex}`
    }
  }))

export const buttonStyle = createStyles(({ typography }) => ({
  width: '100%',
  fontSize: typography.body.sizes.xl
}))

export const iconWrapperStyle = createStyles(
  ({ colors, size, treatments }) => ({
    backgroundColor: colors.success100,
    color: colors.light,
    width: size(4.8),
    height: size(4.8),
    borderRadius: size(4.8),
    justifySelf: 'center',
    ...treatments.center,
    svg: {
      width: size(2.4),
      height: size(2.4)
    }
  })
)

export const cardContainerStyle = createStyles(({ colors, spacing }) => ({
  display: 'grid',
  backgroundColor: colors.light,
  rowGap: spacing(2.5),
  textAlign: 'center'
}))

export const cardHeaderStyle = createStyles(
  ({ colors, typography, spacing }) => ({
    display: 'grid',
    color: colors.surface400,
    rowGap: spacing(0.5),
    fontSize: typography.body.sizes.lg,
    textAlign: 'center'
  })
)

export const cardButtonsContainerStyle = createStyles(({ size, spacing }) => ({
  display: 'grid',
  flexDirection: 'column',
  rowGap: spacing(1),
  button: buttonStyle,
  a: {
    ...buttonStyle,
    padding: size(1.5),
    textAlign: 'center',
    fontWeight: '600',
    color: 'inherit'
  }
}))
