import { Suspense } from 'react'

import Dots from 'lib/ui/Animations/Loading/Dots'
import Box from 'lib/ui/Box'
import Card from 'lib/ui/Card'
import Heading from 'lib/ui/Heading'
import HighlightText from 'lib/ui/HighlightText'
import Text from 'lib/ui/Text'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import useActiveUserInfo from '@/hooks/useActiveUserInfo'
import Button from '@/ui/Button'

const PaymentsContactInfo = () => {
  const { user } = useActiveUserInfo()
  const { invoice } = useCompanyAndInvoiceContext()
  const partner = invoice?.partner
  const customer = invoice?.customer
  return (
    <Suspense fallback={<Dots />}>
      {!!partner && (
        <Box padding={0} margin={[0, 0, 2]}>
          <HighlightText color='grey15' space={1.25}>
            <Heading tag='h3' size='xs'>
              {partner.legalName || partner.name}
            </Heading>
            <Text color='grey25'>{partner.streetAddress}</Text>
            <Text color='grey25'>
              {partner.city}, {partner.state} {partner.postalCode}
            </Text>
          </HighlightText>
        </Box>
      )}
      {!!invoice && (
        <Card padding={1.5}>
          <Heading tag='h3' size='xs'>
            Bill to
          </Heading>
          <Text>{customer?.legalName || customer.name}</Text>
          {customer?.streetAddress && (
            <>
              <Text>{customer.streetAddress}</Text>
              <Text>
                {customer.city}, {customer.state} {customer.postalCode}
              </Text>
            </>
          )}
        </Card>
      )}
      {user?.id && (
        <Box padding={0} margin={[1.5, 0]}>
          <Button variant='outline' buttonSize='md' href='/dashboard'>
            Back to dashboard
          </Button>
        </Box>
      )}
    </Suspense>
  )
}

export default PaymentsContactInfo
