import { ReactNode, Suspense } from 'react'

import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Alert from 'lib/ui/Alert'
import Dots from 'lib/ui/Animations/Loading/Dots'
import Box from 'lib/ui/Box'
import { setBtnStyles } from 'lib/ui/Button/v2/Button'
import Card from 'lib/ui/Card'
import Tooltip from 'lib/ui/Tooltip'
import theme from 'utils/theme'

import img from './background.svg'
import InvoiceInfo from './InvoiceInfo'
import PaymentsContactInfo from './PaymentsContactInfo'
import * as css from './styles'
import DefaultLayout from '../DefaultLayout'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import Button from '@/ui/Button/v2'
import InvoiceDownload from '@/ui/Pdfs/InvoiceDownload'

const InvoiceLayout = ({
  children,
  title = 'Pay invoice'
}: {
  children?: ReactNode
  title?: string
}) => {
  const { brandColor, invoice } = useCompanyAndInvoiceContext()

  const status = invoice?.status
  const isPayable =
    status === InvoiceStatus.Overdue || status === InvoiceStatus.Upcoming

  const disablePayButton = invoice?.outstandingAmount <= 0

  return (
    <Suspense fallback={<Dots dotSize={2} />}>
      {!!brandColor && (
        <DefaultLayout
          center
          alignment='left'
          useSideBottom
          sideContent={<PaymentsContactInfo />}
          styles={{
            content: {
              backgroundImage: `url('${img}')`,
              backgroundColor: brandColor.hex
            }
          }}
          title={title}
        >
          <Box padding={0} width={55}>
            <div css={css.containerStyle(brandColor)}>
              <Card
                shadow='soft'
                borderRadius='xxl'
                styles={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                padding={[3, 2]}
              >
                {!children ? (
                  <>
                    <InvoiceInfo />
                    {!isPayable ? (
                      <Alert variant='warning' showIcon>
                        This invoice has a status of {status}
                      </Alert>
                    ) : (
                      <>
                        <Tooltip id='disabled-pay-button-tooltip' />
                        <div
                          data-tooltip-id='disabled-pay-button-tooltip'
                          data-tooltip-content='Invalid amount'
                          data-tooltip-hidden={!disablePayButton}
                        >
                          <Button
                            styles={{ ...css.buttonStyle }}
                            href={
                              disablePayButton ? '' : `/${invoice?.id}/payment`
                            }
                            disabled={disablePayButton}
                          >
                            Review &amp; pay invoice
                          </Button>
                        </div>
                      </>
                    )}
                    {invoice && (
                      <InvoiceDownload
                        btnText='Download invoice manually'
                        invoice={invoice}
                        btnProps={{
                          styles: {
                            ...setBtnStyles('outline'),
                            width: '100%',
                            gap: theme.spacing(0.5),
                            marginTop: theme.spacing(1),
                            fontSize: theme.size(1.8)
                          },
                          variant: 'outline',
                          buttonSize: 'lg',
                          display: 'inline-block'
                        }}
                        showIcon={false}
                      />
                    )}
                  </>
                ) : (
                  <>{children}</>
                )}
              </Card>
            </div>
          </Box>
        </DefaultLayout>
      )}
    </Suspense>
  )
}

export default InvoiceLayout
