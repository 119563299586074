import { Tooltip as BaseTooltip, ITooltip } from 'react-tooltip'
import { theme } from 'utils/theme'
import colors from 'utils/theme/colors'

export interface IExtendedTooltip extends ITooltip {
  color?: string
  backgroundColor?: string
  offsetX?: number
  maxWidth?: number
}

const Tooltip = (props: IExtendedTooltip) => (
  <BaseTooltip
    opacity={1}
    style={{
      color: props.color || colors.light,
      backgroundColor: props.backgroundColor || colors.primary,
      fontSize: theme.fontSizes.xs,
      marginLeft: `${props.offsetX}px`,
      maxWidth: `${props.maxWidth || 300}px`,
      whiteSpace: 'normal',
      fontWeight: 400,
      zIndex: 1
    }}
    {...props}
  />
)

export default Tooltip
