import { Suspense } from 'react'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Dots from 'lib/ui/Animations/Loading/Dots'
import Divider from 'lib/ui/Divider'
import Heading from 'lib/ui/Heading'
import MultiLineText from 'lib/ui/MultiLineText'
import Text from 'lib/ui/Text'
import { formatCurrency } from 'utils/numbers'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import { formatDate } from '@/utils/dates/format'
dayjs.extend(utc)

const InvoiceInfo = () => {
  const { invoice } = useCompanyAndInvoiceContext()
  return (
    <Suspense fallback={<Dots dotSize={2} />}>
      {!!invoice && (
        <>
          <Heading tag='h1' size='lg' margin={[0, 0, 2]}>
            Invoice #{invoice.invoiceNumber}
          </Heading>
          <Text size='sm'>Created on</Text>
          <Heading tag='h2' size='sm' margin={[0]}>
            {formatDate(invoice.createdAt, 'MMM DD, YYYY')}
          </Heading>

          {invoice.dueDate && (
            <>
              <Divider margin={1.5} color='grey15' />
              <Text size='sm'>Due</Text>
              <Heading tag='h2' size='sm'>
                {formatDate(invoice.dueDate, 'MMM DD, YYYY')}
              </Heading>
            </>
          )}
          {invoice.productName && (
            <>
              <Divider margin={1.5} color='grey15' />
              <Text size='sm'>Memo</Text>
              <Heading tag='h2' size='sm'>
                <MultiLineText>{invoice.productName}</MultiLineText>
              </Heading>
            </>
          )}
          <Divider margin={1.5} color='grey15' />
          <Text size='sm'>Outstanding amount</Text>
          <Heading tag='h2' size='sm' margin={[0, 0, 2]}>
            {formatCurrency({ amount: invoice.outstandingAmount })}
          </Heading>
        </>
      )}
    </Suspense>
  )
}

export default InvoiceInfo
